
import { Component, Prop, Vue } from "vue-property-decorator";
import metaDataModule from "@/store/modules/metaDataModule";
import tasqFeedbackModule from "@/store/modules/tasqFeedbackModule";

@Component
export default class TasqWellFinalStepFeedback extends Vue {
  @Prop({ type: Object, required: true, default: {} }) tasq?: any;

  recordMoreActions() {
    tasqFeedbackModule.resetAllData();
    tasqFeedbackModule.setStepper(1);
  }

  moveToNextTasqInList() {
    const activeTasqDiv: any = document.getElementById(this.tasq.id)!;
    if (activeTasqDiv && activeTasqDiv.nextSibling) {
      const nextTasqID = activeTasqDiv.nextSibling?.id;
      const currentRouteName: any = this.$route.name;

      if (nextTasqID) {
        this.$router.push({
          name: currentRouteName,
          params: {
            id: nextTasqID || "",
          },
          query: { type: "id" },
        });
        this.recordMoreActions()
      }
    }
  }
}
